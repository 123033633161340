import React, { Component } from 'react';

const Footer = () => {
  return (
    <div >
      
      <div className="footer">
        <p>Copyright © 2018 Michael Bido, Good Therapy Band. All rights reserved.</p>
      </div>
      
    </div>
  )
}

export default Footer;